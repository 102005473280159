import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core'
import {ReviewSection} from '../../../../core/content/types/review-section.types'
import {RatingModule} from '../../../../craft/rating/rating.module'
import {SliderModule} from '../../../../craft/slider/slider.module'
import {ReviewCardComponent} from './review-card/review-card.component'
import {TranslocoDirective} from '@ngneat/transloco'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'

@Component({
  selector: 'bgo-reviews-section',
  templateUrl: './reviews-section.component.html',
  styleUrls: ['./reviews-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentSectionComponent, RatingModule, SliderModule, ReviewCardComponent, TranslocoDirective],
  standalone: true,
})
export class ReviewsSectionComponent {
  @Input() reviewSection!: ReviewSection
  @Output() buttonClicked = new EventEmitter<void>()
}
