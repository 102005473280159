<div
  class="h-full w-38 rounded-3 border border-grey-200 px-md py-lg sm:w-44"
  *transloco="let t"
  [class.max-h-26]="!isExpanded"
>
  <div class="flex">
    <div class="h-6 min-w-6 rounded-full bg-petrol-500 text-center leading-6 text-white">
      <span class="text-lg">{{ author[0] }}</span>
    </div>
    <div class="w-full pl-sm">
      <div class="flex justify-between">
        <div class="flex items-center">
          <div>
            <div class="text-base">
              <span class="font-bold">{{ author }}</span>
              {{ t('homepage.reviews.via') }}
              <span class="font-bold">{{ platform }}</span>
            </div>
            @if (rating) {
              <div class="text-sm">
                <cft-rating [rating]="rating"></cft-rating>
              </div>
            }
          </div>
        </div>
        <div class="text-2xl text-gold-500">
          <fa-icon [icon]="quoteIcon"></fa-icon>
        </div>
      </div>

      <div class="mt-md text-sm">
        {{ text | slice: 0 : truncateLength }}
        @if (isTruncated) {
          <span>...</span>
        }
      </div>
      <div class="mt-xs text-sm">
        @if (isTruncated) {
          <button (click)="toggleShowMoreText()" class="cursor-pointer">
            {{ t('homepage.reviews.showMore') }}
            <fa-icon [icon]="showMoreIcon"></fa-icon>
          </button>
        }
        @if (isExpanded) {
          <button (click)="toggleShowMoreText()" class="cursor-pointer">
            {{ t('homepage.reviews.showLess') }}
            <fa-icon [icon]="showLessIcon"></fa-icon>
          </button>
        }
      </div>
    </div>
  </div>
</div>
