import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {faQuoteRight} from '@fortawesome/pro-solid-svg-icons/faQuoteRight'
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import {faChevronUp} from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {TranslocoDirective} from '@ngneat/transloco'
import {SlicePipe} from '@angular/common'
import {RatingModule} from '../../../../../craft/rating/rating.module'

export const defaultReviewTruncateLength = 75

@Component({
  selector: 'bgo-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaIconComponent, TranslocoDirective, SlicePipe, RatingModule],
  standalone: true,
})
export class ReviewCardComponent {
  @Input()
  text!: string

  @Input()
  author!: string

  @Input()
  platform: string | undefined

  @Input()
  rating: number | undefined

  readonly quoteIcon = faQuoteRight
  readonly defaultTruncateLength = defaultReviewTruncateLength
  readonly showMoreIcon = faChevronDown
  readonly showLessIcon = faChevronUp
  truncateLength = this.defaultTruncateLength

  get isTruncated(): boolean {
    return this.text.length > this.defaultTruncateLength && this.truncateLength === this.defaultTruncateLength
  }

  get isExpanded(): boolean {
    return this.truncateLength > this.defaultTruncateLength
  }

  toggleShowMoreText(): void {
    if (this.truncateLength === this.defaultTruncateLength) {
      this.truncateLength = this.text.length
      return
    }

    this.truncateLength = this.defaultTruncateLength
  }
}
